import { FC } from "react";
import React from "react";

export enum USER_ROLE {
  ADMIN = 'ROLE_ADMIN',
  ADMIN_NOT_PSYCHOLOGIST = 'ROLE_ADMIN_NOT_PSYCHOLOGIST',
  SPECIALIST = 'ROLE_SPECIALIST',
  EXTERNAL_SPECIALIST = 'ROLE_EXTERNAL_SPECIALIST',
}

export const ROLE_MAP: ({ [key: string]: string }) = {
  ROLE_ADMIN: "Dyrektor / Administrator - psycholog",
  ROLE_SPECIALIST: "Specjalista",
  ROLE_ADMIN_NOT_PSYCHOLOGIST: "Dyrektor / Administrator - niepsycholog",
  ROLE_EXTERNAL_SPECIALIST: "Specjalista zewnętrzny",
}

interface RoleProps {
  value: string
}

const Role: FC<RoleProps> = ({value}) => {
  return <>{ ROLE_MAP[value] || value }</>
};

export default Role;
