import { Button, Result } from "antd";
import { FC } from "react";
import { Link } from "react-router-dom";
import BlankLayout from "../../layouts/BlankLayout/BlankLayout";
import React from "react";

const NoAccessPage: FC = () => {
    return (
        <BlankLayout title="Brak dostępu">
            <Result
                status="403"
                title="403"
                subTitle="Brak dostępu do strony o podanym adresie"
                extra={(<Link to="/">
                    <Button type="primary" icon="left">
                        Powrót na stronę główną
                    </Button>
                </Link>)}
            />
        </BlankLayout>
    );
};

export default NoAccessPage;
