import { gql } from "apollo-boost";
import {LOCATION_CHANGE, LocationChangeAction, push} from "connected-react-router";
import { call, fork, put, takeEvery } from "redux-saga/effects"
import client from "../../../client";
import { invalidateTenantContext, loadedContext } from "./tenantContextActions";
import { TenantContextTypes } from "./tenantContextTypes";
import {setContext} from "../../../fetch";
import queryString from "query-string";

const CONTEXT_QUERY = gql`
    query ContextQuery {
        tenant {
            id
            slug
            active
            pilot
        }
    }
`;

export default function* () {
    yield fork(checkTenantContextSaga);
    yield takeEvery(LOCATION_CHANGE, locationChangeSaga);
    yield takeEvery(TenantContextTypes.INVALIDATE, redirectToErrorPageSaga);
}

function* checkTenantContextSaga() {
    const {data: {tenant}} = yield call(client.query, {query: CONTEXT_QUERY});

    if (!tenant || !tenant.active) {
        yield put(invalidateTenantContext());
    } else {
        yield put(loadedContext(tenant.slug, tenant.pilot));
    }
}

function *locationChangeSaga({payload}: LocationChangeAction) {
    const {search} = payload.location;
    const match = queryString.parse(search)
    if (match.context) {
        yield call(setContext, match.context as string);
    }
}

function* redirectToErrorPageSaga() {
    yield put(push("/404"));
}
