import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { onError } from "apollo-link-error";
import { HttpLink } from 'apollo-link-http';
import clientFetch from "./fetch";
import introspectionQueryResultData from "./fragmentTypes.json";
import {store} from "./index";
import {clearSession} from "./components/Session/store/sessionActions";
import { ServerError } from 'apollo-link-http-common';

const fragmentMatcher = new IntrospectionFragmentMatcher({introspectionQueryResultData});
const cache = new InMemoryCache({fragmentMatcher});

const httpLink = new HttpLink({
    uri: '/graphql/',
    fetch: clientFetch,
});

const errorLink = onError((error) => {
    if (error.networkError
        && (error.networkError as ServerError).statusCode
        && (error.networkError as ServerError).statusCode === 401
    ) {
        store.dispatch(clearSession())
        window.location.href = window.location.origin + "/login"
    }

    if (error.networkError || !error.graphQLErrors) {
        throw error;
    }

    if (1 === error.graphQLErrors.length && !error.graphQLErrors[0].path) {
        throw error;
    }
});

const link = ApolloLink.from([
    errorLink,
    httpLink,
]);

const client = new ApolloClient({
    cache,
    link,
    defaultOptions: {
        watchQuery: {
            fetchPolicy: "cache-and-network",
            notifyOnNetworkStatusChange: true,
        },
    },
});

export default client;
