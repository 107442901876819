import React, { FC, ReactElement } from "react";
import { useSelector } from "react-redux";
import {Redirect, Route, RouteProps} from "react-router";
import { getUserRole } from "../../../store/modules/currentUser/currentUserSelectors";
import NoAccessPage from "../../NoAccess/NoAccessPage";
import { USER_ROLE } from "../../Role/Role";
import { hasSession } from "../../Session/store/sessionSelectors";
import Loader from "../../Utils/Loader/Loader";

export function createConditionalRoute<Props>(
    condition: (props: Props) => boolean | ReactElement
): FC<Props & RouteProps> {
    return (props) => {
        const fallback = condition(props);
        switch (fallback) {
            case true:
                return <Route {...props} />;
            case false:
                return null;
            default:
                return (
                    <Route
                        {...props}
                        component={undefined}
                        children={undefined}
                        render={() => fallback}/>
                );
        }
    };
};

export const AuthRoute = createConditionalRoute(
    () => useSelector(hasSession) ? <Redirect to="/" /> : true
);
export const SecureRoute = createConditionalRoute<{roles?: USER_ROLE[]} & RouteProps>((props) => {
    const role = useSelector(getUserRole);
    const session = useSelector(hasSession);

    if (!session) {
        return <Redirect to="/login" />
    }

    if (null == role) {
        return <Loader size="large" />;
    }

    if (props.roles && !props.roles.includes(role)) {
        return <NoAccessPage />;
    }

    return true;
});
