import { Layout } from "antd";
import * as React from "react";
import { FC } from "react";
import { Helmet } from "react-helmet-async";
import styles from "./blankLayout.module.css";

const {Content} = Layout;

interface BlankLayoutProps {
    title: string;
}

const BlankLayout: FC<BlankLayoutProps> = (props) => {
    return (
        <Layout className={styles.layout}>
            <Helmet>
                <title>{props.title}</title>
            </Helmet>
            <Content>
                {props.children}
            </Content>
        </Layout>
    );
};

export default BlankLayout;
