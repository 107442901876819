import { gql } from "apollo-boost";
import {LOCATION_CHANGE, LocationChangeAction} from "connected-react-router";
import { call, delay, put, select, takeEvery, takeLatest, throttle } from "redux-saga/effects";
import client from "../../../client";
import { CURRENT_USER_FRAGMENT } from "../../../store/modules/currentUser/currentUserSaga";
import { decodeJsonWebToken } from "../services/decodeJsonWebToken";
import sessionPersister from "../services/sessionPersister";
import { clearSession, initSession, InitSessionAction, sessionRefresh, sessionStart } from "./sessionActions";
import { getSessionToken } from "./sessionSelectors";
import { SessionTypes } from "./sessionTypes";
import queryString from "query-string";
import {setToken} from "../../../fetch";

export default function* sessionSaga() {
    yield takeLatest(SessionTypes.INIT, initSessionSaga);
    yield throttle(1000, SessionTypes.REFRESH, refreshSessionSaga);
    yield takeEvery(SessionTypes.CLEAR, clearSessionSaga);
    yield takeEvery(LOCATION_CHANGE, locationChangeSaga);

    const token = yield select(getSessionToken);
    if (token) {
        yield put(initSession(token));
    }
}

function* initSessionSaga({payload: {token}}: InitSessionAction) {
    const payload: ReturnType<typeof decodeJsonWebToken> = yield call(decodeJsonWebToken, token);
    yield call(sessionPersister.write, token);
    yield put(sessionStart(payload));
    const ttl = +payload.expirationDate - +new Date();
    const activeTime = Math.min(MAX_ACTIVE_TIME, ttl);
    const inactiveTime = ttl-activeTime;
    if (inactiveTime) {
        yield delay(inactiveTime);
    }
    yield throttle(activeTime, LOCATION_CHANGE, activitySaga);
    yield delay(activeTime);
    yield put(clearSession());
}

function* activitySaga() {
    yield put(sessionRefresh());
}

function* refreshSessionSaga() {
    try {
        const {data: {session: {token}}} = yield call(client.query, {
            query: SESSION_TOKEN_QUERY,
            fetchPolicy: "network-only",
        });
        yield put(initSession(token));
    } catch (e) {
        yield put(clearSession());
    }
}

function *locationChangeSaga({payload}: LocationChangeAction) {
    // print and tenant toggle
    const {search} = payload.location;
    const match = queryString.parse(search)
    const token = (match.token as string) || null
    if (token) {
        yield put(initSession(token as string));
    }
    yield call(setToken, token);

    if (token && ["", "/"].includes(payload.location.pathname)) { // tenant toggle (homepage detected)
        window.location.href = window.location.origin // clear token query string in url
    }
}

function* clearSessionSaga() {
    yield call(() => client.stop());
    yield call(() => client.clearStore());
    yield call(sessionPersister.clear);
    yield call(() => console.clear());
}

const SESSION_TOKEN_QUERY = gql`
    query SessionToken {
        session {
            token
            ...CurrentUser
        }
    }
    ${CURRENT_USER_FRAGMENT}
`;

const MAX_ACTIVE_TIME = 1000 * 60 * 30;
