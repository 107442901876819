import React, { lazy, Suspense } from 'react';
import { hot } from "react-hot-loader";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { hasLoadedContext } from "../../store/modules/tenantContext/tenantContextSelectors";
import { USER_ROLE } from "../Role/Role";
import Loader from "../Utils/Loader/Loader";
import { AuthRoute, SecureRoute } from "./components/Route";

const DashboardPage = lazy(() => import(`../Dashboard/DashboardPage`));
const LoginFormPage = lazy(() => import("../LoginForm/LoginFormPage"));
const PageNotFound = lazy(() => import("../NotFound/NotFoundPage"));
const UsersListPage = lazy(() => import("../Users/UsersList/UserListPage"));
const DiagnosisListPage = lazy(() => import("../Diagnosis/DiagnosisList/DiagnosisListPage"));
const ToolsListPage = lazy(() => import("../ToolsList/ToolsListPage"));
const ToolCardPage = lazy(() => import("../ToolCard/ToolCardPage"));
const RegisterUserPage = lazy(() => import("../Users/RegisterUser/RegisterUserPage"));
const RegisterDiagnosisPage = lazy(() => import("../Diagnosis/RegisterDiagnosis/RegisterDiagnosisPage"));
const ExaminedPersonRegistrationFormPage = lazy(() => import("../ExaminedPersonRegistrationForm/ExaminedPersonRegistrationFormPage"));
const ExaminedPersonListPage = lazy(() => import("../ExaminedPersonsList/ExaminedPersonListPage"));
const ExaminedPersonCardPage = lazy(() => import("../ExaminedPersonCard/ExaminedPersonCardPage"));
const ExaminationResultsPage = lazy(() => import("../ExaminationResults/ExaminationResultsPage"));
const UpdateUserPage = lazy(() => import("../Users/UpdateUser/UpdateUserPage"));
const ActivateUserPage = lazy(() => import("../ActivateUserPage/ActivateUserPage"));
const UpdateTenantPage = lazy(() => import("../UpdateTenant/UpdateTenantPage"));
const UpdateDiagnosisPage = lazy(() => import("../Diagnosis/UpdateDiagnosis/UpdateDiagnosisPage"));
const DiagnosisPreviewPage = lazy(() => import("../Diagnosis/DiagnosisPreview/DiagnosisPreviewPage"));
const DiagnosisResultPage = lazy(() => import("../Diagnosis/DiagnosisResultPdfPage/DiagnosisResultsPdfPage.component"));
const SendResetPasswordEmailForm = lazy(() => import("../ResetPassword/SendResetPasswordEmailForm"));
const ResetPasswordForm = lazy(() => import("../ResetPassword/ResetPasswordForm"));
const TimetableContainer = lazy(() => import("../Timetable/TimetableContainer"));
const AppointmentList = lazy(() => import("../Appointment/AppointmentList"));
const UpdateOpinionPage = lazy(() => import("../Opinion/UpdateOpinionPage"));
const OpinionPreviewPage = lazy(() => import("../Opinion/OpinionPreviewPage"));
const ExaminationsProgressListPage = lazy(() => import("../ExaminationsProgressList/ExaminationsProgressListPage"));
const SchoolsListPage = lazy(() => import("../Schools/SchoolsList/SchoolsListPage"));
const SchoolRegistrationPage = lazy(() => import("../Schools/SchoolRegistrationForm/SchoolsRegistrationFormPage"));
const SchoolEditFormPage = lazy(() => import("../Schools/SchoolEditPage/SchoolEditFormPage"));
const SystemStatusPage = lazy(() => import("../SystemStatus/SystemStatusPage"));
const PostdiagnosticMaterialsPage = lazy(() => import("../PostdiagnosticMaterials/PostdiagnosticMaterialsPage"));
const ReportListPage = lazy(() => import("../ReportList/ReportListPage"));
const UserDiagnosisListPage = lazy(() => import("../Diagnosis/DiagnosisList/UserDiagnosisListPage"));
const ManualsAndInstructionsPage = lazy(() => import("../ManualsAndInstructions/ManualsAndInstructionsPage"))

const Router: React.FC = () => {
    const loading = !useSelector(hasLoadedContext);

    if (loading) {
        return <Loader size="large" />;
    }

    return (
        <Suspense fallback={<Loader />}>
            <Switch>
                <SecureRoute path="/" exact component={DashboardPage}/>
                <SecureRoute path="/users" roles={[USER_ROLE.ADMIN, USER_ROLE.ADMIN_NOT_PSYCHOLOGIST]}>
                    <Switch>
                        <Route path="/users/list/:page(\d+)?" exact component={UsersListPage}/>
                        <Route path="/users/register" exact component={RegisterUserPage}/>
                        <Route path="/users/:userId/update" exact component={UpdateUserPage}/>
                        <Route path="/users/:userId/timetable" exact component={TimetableContainer}/>
                        <Route path="/users/:userId/activate" exact component={ActivateUserPage}/>
                        <Route component={PageNotFound}/>
                    </Switch>
                </SecureRoute>
                <SecureRoute path="/tenants/update" roles={[USER_ROLE.ADMIN]} exact component={UpdateTenantPage} />
                <SecureRoute path="/tools/list/:page(\d+)?" exact component={ToolsListPage} />
                <SecureRoute path="/tools/:toolCode/card" exact component={ToolCardPage} />
                <SecureRoute path="/examined-persons">
                    <Switch>
                        <Route path="/examined-persons/register" exact component={ExaminedPersonRegistrationFormPage} />
                        <Route path="/examined-persons/list" exact component={ExaminedPersonListPage} />
                        <Route path="/examined-persons/:examinedPersonId/card" exact component={ExaminedPersonCardPage} />
                        <Route path="/examined-persons/:examinedPersonId/updateOpinion/:opinionId" exact component={UpdateOpinionPage} />
                        <Route path="/examined-persons/:examinedPersonId/opinionPreview/:opinionId" exact component={OpinionPreviewPage} />
                        <Route path="/examined-persons/examinations-progress" exact component={ExaminationsProgressListPage}/>
                        <Route component={PageNotFound}/>
                    </Switch>
                </SecureRoute>
                <SecureRoute path="/diagnosis" roles={[USER_ROLE.ADMIN, USER_ROLE.SPECIALIST]}>
                    <Switch>
                        <Route path="/diagnosis/register/:examinedPersonId?" exact component={RegisterDiagnosisPage} />
                        <Route path="/diagnosis/list" exact component={DiagnosisListPage} />
                        <Route path="/diagnosis/list/:userId" exact component={UserDiagnosisListPage} />
                        <Route path="/diagnosis/:diagnosisId/update" exact component={UpdateDiagnosisPage} />
                        <Route path="/diagnosis/:diagnosisId/preview" exact component={DiagnosisPreviewPage} />
                        <Route path="/diagnosis/:diagnosisId/result" exact component={DiagnosisResultPage} />
                        <Route component={PageNotFound}/>
                    </Switch>
                </SecureRoute>
                <SecureRoute path="/examination/:examinationId/result" exact component={ExaminationResultsPage} />
                <SecureRoute path="/appointments/list" exact component={AppointmentList} roles={[USER_ROLE.ADMIN, USER_ROLE.SPECIALIST]}/>
                <SecureRoute path="/schools">
                    <Switch>
                        <Route path="/schools/register" exact component={SchoolRegistrationPage} />
                        <Route path="/schools/list" exact component={SchoolsListPage} />
                        <Route path="/schools/:schoolId/edit" exact component={SchoolEditFormPage} />
                        <Route component={PageNotFound}/>
                    </Switch>
                </SecureRoute>
                <SecureRoute path="/reports" exact component={ReportListPage} />
                <SecureRoute path="/system/status" exact component={SystemStatusPage} />
                <SecureRoute path="/materials" exact component={PostdiagnosticMaterialsPage} />
                <SecureRoute path="/manuals" exact component={ManualsAndInstructionsPage} roles={[USER_ROLE.ADMIN, USER_ROLE.SPECIALIST]}/>
                <AuthRoute path="/login" exact component={LoginFormPage}/>
                <Route path="/password">
                    <Switch>
                        <Route path="/password/send-email" exact component={SendResetPasswordEmailForm}/>
                        <Route path="/password/:token/:action" exact component={ResetPasswordForm}/>
                        <Route component={PageNotFound}/>
                    </Switch>
                </Route>
                <Route component={PageNotFound}/>
            </Switch>
        </Suspense>
    );
};

export default hot(module)(Router);
