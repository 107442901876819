import { checkUpdate } from './store/modules/version/versionActions';

const initHeaders: Record<string, string> = {};

const clientFetch: WindowOrWorkerGlobalScope["fetch"] = async (uri, options?: RequestInit) => {
    const store = await import("./").then((m) => m.store);
    const {session} = store.getState();

    const headers = {
        ...(options ? options.headers : {}) as Record<string, string>,
        ...initHeaders,
    }

    if (session) {
        headers['X-Authorization'] = `Bearer ${session.token}`;
    }

    const response = await fetch(uri, {
        ...options,
        headers,
    });

    if (
        response.headers.has(versionHeader) &&
        response.headers.get(versionHeader) !== process.env.REACT_APP_VERSION
    ) {
        store.dispatch(checkUpdate(true));
    }

    return response;
};

export const setContext = (context: string|null) => {
    const headerName = "X-Tenant";
    if (!context) delete initHeaders[headerName];
    else initHeaders[headerName] = context;
}

export const setToken = (token: string|null) => {
    const headerName = "X-Authorization";
    if (!token) delete initHeaders[headerName];
    else initHeaders[headerName] =  `Bearer ${token}`;
}

const versionHeader = 'X-App-Version';

export default clientFetch;
