import { Reducer } from "redux";
import { loadedContext } from "./tenantContextActions";
import { TenantContextTypes } from "./tenantContextTypes";

interface State {
    invalid?: boolean;
    pilot?: boolean;
    loaded: boolean;
    slug?: string;
}

const tenantContextReducer: Reducer<State> = (state = {loaded: false}, action) => {
    switch (action.type) {
        case TenantContextTypes.INVALIDATE: {
            return {
                loaded: true,
                invalid: true,
            };
        }
        case TenantContextTypes.LOADED: {
            const {payload} = action as ReturnType<typeof loadedContext>;
            return {
                loaded: true,
                invalid: false,
                pilot: payload.pilot,
                slug: payload.slug,
            };
        }
    }

    return state;
};

export default tenantContextReducer;
