import {Reducer} from "redux";
import { USER_ROLE } from "../../../components/Role/Role";
import {CurrentUserTypes} from "./currentUserTypes";
import {SetUserDataAction} from "./currentUserActions";

export type CurrentUser = null | {
    id: string,
    name: string,
    surname: string,
    email: string,
    role: USER_ROLE,
    tenantsUrls: string[],
}

const currentUserReducer:Reducer<CurrentUser> = (state = null, action) => {
    switch(action.type) {
        case CurrentUserTypes.SET_USER_DATA:
            const {payload} = action as SetUserDataAction;
            return payload;
    }

    return state;
};

export default currentUserReducer;
