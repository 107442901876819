import { Reducer } from "redux";
import { decodeJsonWebToken } from "../services/decodeJsonWebToken";
import sessionPersister from "../services/sessionPersister";
import { SessionStartAction } from "./sessionActions";
import { SessionTypes } from "./sessionTypes";

type State = null | {
    token: string;
    expirationDate: Date;
    username: string;
}

const sessionReducer: Reducer<State> = (state = null, action) => {
    switch (action.type as SessionTypes) {
        case SessionTypes.START: {
            const { payload } = action as SessionStartAction;
            return payload;
        }
        case SessionTypes.CLEAR: {
            return null;
        }
    }
    return state;
};

export const preloadSessionState = (): State => {
    const token = sessionPersister.read();

    if (null == token) {
        return null;
    }

    const payload = decodeJsonWebToken(token);

    if (payload.expirationDate <= new Date()) {
        return null;
    }

    return payload;
};

export default sessionReducer;
